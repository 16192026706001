module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(min-width: 576px)","sm":"(min-width: 768px)","md":"(min-width: 992px)","l":"(min-width: 1200px)","xl":"(min-width: 1600px)","portrait":"(orientation: portrait)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"theme_color":"#333","background_color":"#333","display":"standalone","scope":"/","start_url":"/","name":"Endre Soo","short_name":"Soo","description":"Professional full stack web development using the latest web technologies. JS, PHP and popular frameworks, CMS solutions. SQL or NoSQL databases. Figma designs","icon":"static/icons/icon-512x512.png","icons":[{"src":"static/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png","purpose":"any maskable"},{"src":"static/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png","purpose":"any maskable"},{"src":"static/icons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"static/icons/icon-256x256.png","sizes":"256x256","type":"image/png","purpose":"any maskable"},{"src":"static/icons/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"any maskable"},{"src":"static/icons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"449c8f4dea0dcc758ee05b682b226623"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
